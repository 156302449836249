<template>
  <v-container>
    <v-card>
      <v-container fluid>
        <div>This form lets you invite a user to stemble or resend invitation emails.</div>
        <v-form @submit.prevent="inviteUser">
          <v-text-field v-model="email" :label="$t('email')" />
          <v-btn type="submit" :loading="isLoading" :disabled="isLoading"> Invite </v-btn>
        </v-form>
      </v-container>
    </v-card>
  </v-container>
</template>
<script>
import {sendInvitationEmail} from '@/users/api/user-invitations';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import SuccessSnackbarPlugin from '@/common/plugins/success-snackbar';

export default {
  name: 'InviteUser',
  data() {
    return {
      email: null,
    };
  },
  computed: {
    isLoading() {
      return this.$loadingFlags.isLoading(LoadingFlag.InviteUser);
    },
  },
  methods: {
    inviteUser() {
      return this.$loadingFlags
        .loadingHandler(LoadingFlag.InviteUser, async () => {
          await sendInvitationEmail(this.email, {resend: true});
          SuccessSnackbarPlugin.snackbar.open({
            message: 'Invitation sent',
            showButton: true,
            color: 'success',
            timeout: 1000 * 60,
          });
        })
        .catch(this.$errorReporting.errorDialogHandler);
    },
  },
};
</script>
